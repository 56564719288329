import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
  FC,
} from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Lead,
  useGetLeadDetailsQuery,
  useUpdateLeadBySellerMutation,
} from "generated/graphql";
import Button from "components/Button";
import leadSchema, { LeadSchema } from "settings/yup/schemas/leadsSchema";
import { formatDate } from "utils/dateFormats";
import { CHECKOUT_LEADS_OVERVIEW_PAGE_PATH, CLASSIC_LEADS_OVERVIEW_PAGE_PATH } from "routes";
import { useCrmChannel } from "hooks/useCrmChannel";

import { LeadDetailsParams } from "../types";
import Form from "../components/Form";
import {
  LEAD_REFETCH_QUERIES,
  CHECKOUT_LEAD_DETAILS_REFETCH_QUERIES,
} from "../graphql/updateLeadBySellerMutation";
import SpecificSections from "./SpecificSections";
import { ConfigurationDetails } from "./Shared/ConfigurationDetails";

const Details: FC = () => {
  const [formChanged, setFormChanged] = useState(false);

  const { t } = useTranslation();
  const newToast = useToast();
  const history = useHistory();

  const { getCrmChannel, removeCrmChannelSearchParam } = useCrmChannel();

  const params = useParams<LeadDetailsParams>();

  const {
    data,
    loading,
    error,
  } = useGetLeadDetailsQuery({
    variables: {
      id: params?.id,
    },
    fetchPolicy: "network-only",
  });

  const lead = data?.lead as Lead;
  const isFormDisabled = !!loading || !!error;
  const isCheckoutLead = lead?.isCheckoutLead;

  const {
    handleSubmit,
    formState,
    register,
    control,
    reset,
  } = useForm<LeadSchema>({
    resolver: yupResolver(leadSchema),
    mode: "onChange",
    defaultValues: {
      salesperson: "",
      status: undefined,
      deliveryDate: undefined,
    },
  });

  const handleGoBack = (): void => {
    if (history.length > 1) {
      return history.goBack();
    }

    const previousRoute = isCheckoutLead
      ? CHECKOUT_LEADS_OVERVIEW_PAGE_PATH
      : CLASSIC_LEADS_OVERVIEW_PAGE_PATH;

    return history.push(previousRoute);
  };

  useEffect(() => {
    if (error) {
      newToast({
        title: t("errors.something_went_wrong"),
        description: error.message,
        status: "error",
      });
    }
  }, [
    newToast,
    error,
    t,
  ]);

  const refetchQueries = isCheckoutLead
    ? [
      ...CHECKOUT_LEAD_DETAILS_REFETCH_QUERIES,
      ...LEAD_REFETCH_QUERIES,
    ]
    : LEAD_REFETCH_QUERIES;

  const [
    updateLead,
    mutationProps,
  ] = useUpdateLeadBySellerMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: removeCrmChannelSearchParam,
  });

  useEffect(() => {
    if (isFormDisabled) {
      return;
    }

    reset({
      salesperson: lead?.assignee?.seller?.contactId,
      status: lead?.status,
      deliveryDate: lead?.deliveryDate
        ? new Date(lead?.deliveryDate)
        : undefined,
    });
  }, [
    isFormDisabled,
    reset,
    lead,
  ]);

  const onSubmit = useCallback((values: LeadSchema) => {
    updateLead({
      variables: {
        id: params?.id,
        params: {
          deliveryDate: values.deliveryDate ? formatDate(values.deliveryDate) : null,
          mileage: parseInt(values.mileage?.split(",")?.join("") ?? ""),
          duration: values.duration?.replace(".", ""),
          dealerNotes: values.dealerNotes,
          assignee: values.salesperson,
          status: values.status,
          dealerCrmChannelSource: getCrmChannel(),
        },
      },
    })
      .then(() => {
        newToast({
          title: t("success.success"),
          description: t("success.lead_updated"),
          status: "success",
        });
        reset(undefined, {
          keepDirty: false,
        });
      })
      .catch((err) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: err.message,
          status: "error",
        });
      });
  }, [
    getCrmChannel,
    updateLead,
    newToast,
    params,
    reset,
    t,
  ]);

  const unsavedChangesNumber = Object.keys(formState.dirtyFields).length;

  useEffect(() => {
    if (unsavedChangesNumber && !formChanged) {
      newToast({
        title: t("warnings.warning"),
        description: t("warnings.dont_forget_to_save"),
        status: "info",
      });

      setFormChanged(true);
    }
  }, [
    unsavedChangesNumber,
    formChanged,
    newToast,
    t,
  ]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDir="row-reverse"
        mb="6"
      >
        <Button
          onClick={handleGoBack}
          Icon={ArrowBackIcon}
          variant="outline"
          label="Back"
          pr="4"
          pl="2"
        />
      </Flex>

      <Stack
        spacing="40px"
        w="full"
      >
        <Form
          loading={isFormDisabled}
          formState={formState}
          isCheckout={isCheckoutLead}
          register={register}
          control={control}
          lead={lead}
        />

        <ConfigurationDetails
          configurationDetails={lead?.configurationDetails}
          isLoading={loading}
        />

        <SpecificSections
          isCheckout={isCheckoutLead}
          isLoading={loading}
          lead={lead}
        />

        <Flex
          justifyContent="space-between"
          flexDir="row-reverse"
          alignItems="center"
        >
          <Button
            label={t("components.buttons.save")}
            isDisabled={!unsavedChangesNumber}
            isLoading={mutationProps.loading}
            type="submit"
          />

          {
            !!unsavedChangesNumber && (
              <Text
                color="secondary.500"
                fontWeight="600"
              >
                {unsavedChangesNumber}
                {" "}
                {t("leads.details.form.unsaved_changes")}
              </Text>
            )
          }
        </Flex>
      </Stack>
    </form>
  );
};

export default Details;
