import { isWithinInterval, subMonths } from "date-fns";

const normalizeDate = (date: Date): Date => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);

  return normalized;
};

export const isWithinMonthsRange = (date: Date, monthsRange: number): boolean => {
  const today = normalizeDate(new Date());
  const start = normalizeDate(subMonths(today, monthsRange));

  return isWithinInterval(normalizeDate(date), {
    start,
    end: today,
  });
};
