import React from "react";
import {
  Flex,
  Skeleton,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import { skeletonStyles } from "views/Leads/Details/Shared/styles";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import getHousingCost from "utils/getHousingCost";
import getHousingSituationValue from "utils/getHousingSituationValue";
import getHousingCostLabel from "utils/getHousingCostLabel";
import { formatDate, germanDateFormat } from "utils/dateFormats";

import { HousingSituationProps } from "./types";

const HousingSituation: React.FC<HousingSituationProps> = ({
  isLoading,
  lead,
}) => (
  <Flex w="full" flexDir="column">
    <LeadDetailsCard
      title={i18n.t("leads.details.form.housing_situation")}
    >
      {
        isLoading
          ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
              <Skeleton {...skeletonStyles.full} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            <Flex>
              <Flex flex={1} direction="column">
                <Flex mb={30}>
                  <FakeTextField
                    value={getHousingSituationValue(lead?.housingSituation)}
                    label={i18n.t("leads.details.form.housing_situation")}
                    w="50%"
                  />

                  {
                    lead?.housingSituation && (
                      <FakeTextField
                        value={getHousingCost(lead)}
                        label={getHousingCostLabel(lead?.housingSituation)}
                        w="50%"
                      />
                    )
                  }
                </Flex>

                <Flex mb={30}>
                  <FakeTextField
                    value={`${lead?.street ?? ""} ${lead?.number ?? ""}`}
                    label={i18n.t("leads.details.form.street")}
                    w="50%"
                  />

                  <FakeTextField
                    value={lead?.zipCode}
                    label={i18n.t("leads.details.form.postcode")}
                    w="25%"
                  />

                  <FakeTextField
                    value={lead?.city}
                    label={i18n.t("leads.details.form.city")}
                    w="25%"
                  />
                </Flex>

                <Flex mb={30}>
                  <FakeTextField
                    value={formatDate(lead?.residentSince, germanDateFormat, "-")}
                    label={i18n.t("leads.details.form.resident_since")}
                    w="25%"
                  />
                </Flex>
              </Flex>
            </Flex>
          )
      }
    </LeadDetailsCard>
  </Flex>
);

export default HousingSituation;
