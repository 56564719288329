import React from "react";
import {
  Box,
  Flex,
  Image,
  Skeleton,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import { skeletonStyles } from "views/Leads/Details/Shared/styles";

import { CustomerAndVehicleSectionFormProps } from "./types";

const CustomerAndVehicle: React.FC<CustomerAndVehicleSectionFormProps> = ({
  lead,
  loading,
}) => (
  <Stack mb={4}>
    <Flex justifyContent="space-between">
      <Flex w="full" mr={4} flexDir="column">
        <LeadDetailsCard
          title={i18n.t("leads.details.form.customer")}
        >
          {
            loading ? (
              <Flex flexDir="column" w="full">
                <Skeleton {...skeletonStyles.sm} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton w="full" h={24} />
              </Flex>
            ) : (
              <>
                {
                  // This field is shown only on classic leads details page
                  // for checkout leads the field is `companyName` instead of `company`
                  lead?.company && (
                    <Flex mb={30}>
                      <FakeTextField
                        value={lead.company}
                        label={i18n.t("leads.details.form.company_name")}
                        mr={8}
                      />
                    </Flex>
                  )
                }
                <Flex mb={30}>
                  <Flex w="50%">
                    <FakeTextField
                      value={lead?.salutation}
                      label={i18n.t("leads.details.form.salutation")}
                      mr={8}
                    />
                    <FakeTextField
                      value={lead?.firstName}
                      label={i18n.t("leads.details.form.first_name")}
                      mr={8}
                    />
                  </Flex>
                  <FakeTextField
                    value={lead?.lastName}
                    label={i18n.t("leads.details.form.last_name")}
                  />
                </Flex>
                <Flex w="full" mb={30}>
                  <Flex flexDir="column" w="50%">
                    <Text fontSize={12} mb={1} color="gray.400" mr={8}>
                      {i18n.t("leads.details.form.email")}
                    </Text>
                    <Link
                      as="a"
                      href={`mailto:${lead?.email}`}
                    >
                      <Text fontSize={16} fontWeight="500">
                        {lead?.email || "-"}
                      </Text>
                    </Link>
                  </Flex>
                  <FakeTextField
                    value={lead?.phone}
                    label={i18n.t("leads.details.form.phone")}
                  />
                </Flex>
                <Flex w="full">
                  <FakeTextField
                    value={lead?.notes}
                    label={i18n.t("leads.details.form.notes")}
                    mb={8}
                  />
                </Flex>
              </>
            )
          }
        </LeadDetailsCard>
      </Flex>
      <Flex w="full" flexDir="column">
        <LeadDetailsCard title={i18n.t("leads.details.form.vehicle")}>
          {
            loading ? (
              <>
                <Flex flexDir="column" mr={4}>
                  <Skeleton {...skeletonStyles.sm} />
                  <Skeleton {...skeletonStyles.full} />
                </Flex>
                <Flex>
                  <Skeleton w={32} h={24} mr={4} />
                  <Skeleton {...skeletonStyles.full} />
                </Flex>
              </>
            )
              : (
                <>
                  <Flex mb={30}>
                    <FakeTextField
                      value={lead?.offerShortId}
                      label={i18n.t("leads.details.form.offer_id")}
                    />
                  </Flex>
                  <Flex alignItems="flex-start">
                    <Image
                      src={lead?.photoPath}
                      alt={lead?.offerShortId}
                      w={132}
                      mr={8}
                    />

                    <Box mb={30}>
                      <Flex>
                        <Box w="60%">

                          <Flex flexDir="column" minH={16} mb={8}>
                            <Text fontSize={12} mb={1} color="gray.400">
                              {i18n.t("leads.details.form.title")}
                            </Text>
                            <Link
                              href={lead?.offerUrl ?? ""}
                              target="_blank"
                              rel="noreferrer"
                              as="a"
                            >
                              <Text
                                fontSize={16}
                                fontWeight="500"
                              >
                                {lead?.title}
                              </Text>
                            </Link>
                          </Flex>

                          <FakeTextField
                            value={lead?.internalNumber}
                            label={i18n.t("leads.details.form.internal_number")}
                          />
                        </Box>
                        <Box>
                          <FakeTextField
                            value={lead?.vehicleType}
                            label={i18n.t("leads.details.form.vehicle_type")}
                            h={16}
                            mb={8}
                          />
                          <FakeTextField
                            value={lead?.vin}
                            label={i18n.t("leads.details.form.vin")}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </>
              )
          }
        </LeadDetailsCard>
      </Flex>
    </Flex>
  </Stack>
);

export default CustomerAndVehicle;
