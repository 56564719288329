import React from "react";
import {
  Flex,
  Skeleton,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import { skeletonStyles } from "views/Leads/Details/Shared/styles";
import getLeasedBeforeLabel from "utils/getLeasedBeforeLabel";
import { formatCurrency } from "utils/currencyFormat";

import { DebitsProps } from "./types";

const Debits: React.FC<DebitsProps> = ({
  isLoading,
  lead,
}) => (
  <Flex w="full" flexDir="column">
    <LeadDetailsCard
      title={i18n.t("leads.details.form.debits")}
    >
      {
        isLoading
          ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            <Flex mb={30}>
              <FakeTextField
                value={getLeasedBeforeLabel(lead?.leasedBefore)}
                label={i18n.t("leads.details.form.past_leasing")}
                w="50%"
              />

              <FakeTextField
                label={i18n.t("leads.details.form.other_debits")}
                value={formatCurrency({
                  amount: lead?.debits ?? 0,
                  symbolPosition: "before",
                })}
                w="50%"
              />
            </Flex>
          )
      }
    </LeadDetailsCard>
  </Flex>
);

export default Debits;
