import React from "react";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { LeadDetailsParams } from "views/Leads/types";
import checkIsCorporateLead from "utils/checkIsCorporateLead";

import Documents from "./Documents";
import PrivateLead from "./PrivateLead";
import CorporateLead from "./CorporateLead";
import { LeadDetailsSectionCommonProps } from "../../types";

const CheckoutLeadsDetails: React.FC<LeadDetailsSectionCommonProps> = ({
  isLoading,
  lead,
}) => {
  const { id } = useParams<LeadDetailsParams>();

  const isCorporateLead = checkIsCorporateLead(lead);

  return (
    <Stack spacing="40px">
      {
        isCorporateLead ? (
          <CorporateLead
            isLoading={isLoading}
            lead={lead}
          />
        ) : (
          <PrivateLead
            isLoading={isLoading}
            lead={lead}
          />
        )
      }

      <Documents leadId={id} />
    </Stack>
  );
};

export default CheckoutLeadsDetails;
