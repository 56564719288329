import React from "react";

import { LeadDetailsSectionCommonProps } from "../../types";
import RequestLeadRejection from "./RequestLeadRejection";

const ClassicLeadsDetails: React.FC<LeadDetailsSectionCommonProps> = ({
  isLoading,
  lead,
}) => (
  <RequestLeadRejection lead={lead} isLoading={isLoading} />
);

export default ClassicLeadsDetails;
