import React from "react";
import {
  Flex,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

import Textarea from "components/FormComponents/Inputs/Textarea";
import i18n from "translations/i18n";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import { skeletonStyles } from "views/Leads/Details/Shared/styles";

import { NotesSectionFormProps } from "./types";

const Notes: React.FC<NotesSectionFormProps> = ({
  formState,
  lead,
  loading,
  register,
}) => (
  <Stack mb={4}>
    <Flex flexDir="column">
      <LeadDetailsCard
        title={i18n.t("leads.details.form.notes")}
        isExpandable
      >
        {
          loading ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            <Textarea
              {...register("dealerNotes")}
              errors={formState.errors}
              placeholder={i18n.t("leads.details.form.dealer_notes")}
              defaultValue={lead?.dealerNotes}
            />
          )
        }
      </LeadDetailsCard>
    </Flex>
  </Stack>
);

export default Notes;
