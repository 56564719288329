import React from "react";
import {
  Flex,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";

import {
  GetOfferQuery,
  useGetOfferQuery,
} from "generated/graphql";
import { CUSTOM_OFFER_PAGE_LOCATION } from "routes/locations";
import { onQueryError } from "utils/queryHandlers";
import useToast from "hooks/useToast";
import { skeletonStyles } from "views/Leads/Details/Shared/styles";

import { EditVehicleModalProps } from "./types";
import EditVehicleModalContent from "./Content";

const EditVehicleModal: React.FC<EditVehicleModalProps> = ({
  hideModal,
}) => {
  const { id } = CUSTOM_OFFER_PAGE_LOCATION.parseLocationParams(
    window.location,
  );

  const [showToast] = useToast();

  const { data, loading } = useGetOfferQuery({
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    onError(error) {
      onQueryError(error, showToast);
    },
  });

  const defaultFormValues = data?.offer as GetOfferQuery["offer"];

  const makeModalTitle = (): string => (
    [
      defaultFormValues?.vehicle?.brand?.name,
      defaultFormValues?.vehicle?.modelGroup,
      defaultFormValues?.vehicle?.model,
    ]
      .filter(Boolean).join(" - ")
  );

  if (loading && !data) {
    return (
      <Flex p="6" overflowY="scroll" flexDir="column" w="full">
        <Skeleton {...skeletonStyles.sm} />
        <Skeleton {...skeletonStyles.full} />
        <Skeleton {...skeletonStyles.full} />
        <Skeleton {...skeletonStyles.full} />
        <Skeleton {...skeletonStyles.sm} />
        <Skeleton {...skeletonStyles.sm} />
        <Skeleton {...skeletonStyles.full} />
        <Skeleton {...skeletonStyles.full} />
      </Flex>
    );
  }

  return (
    <Stack p="6" overflowY="scroll">
      <Text
        lineHeight="24px"
        fontSize="20px"
        textStyle="h3"
        mb={6}
      >
        {makeModalTitle()}
      </Text>

      <EditVehicleModalContent
        data={defaultFormValues}
        hideModal={hideModal}
      />
    </Stack>
  );
};

export default EditVehicleModal;
